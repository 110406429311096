import React from "react";
import styles from "./product.module.scss";
import * as utils from '../../utils/utils'
function Product(props) {
  
  const {
    name,
    code,
    cxScore,
    lastQuarterCxScore,
    cas,
    lastQuarterCas,
    //volatility,
    primaryRecommendation,
    cxBenchmark,
    casBenchmark,
    responseCount,
    company_region,//EMEA Release- for Tiles,
    companycodes
  } = props.product;
  const { type } = props;
  
  const getRrecommendation = (primaryRecommendation) => {
    return utils.getRrecommendation(primaryRecommendation)
  }

  const getRecommendationColorCode = (primaryRecommendation) => {
    return utils.getRecommendationColorCode(primaryRecommendation)
  }

  const isDashboardPage = () => {
    const path = props.location.pathname;
    let flag = false;
    if (path === '/dashboard') {
      flag = true;
    }
    return flag;
  }

  return (
    <div
      onClick={() => props.showDetail(props.product)}
      className={`${styles.cardContainer} ${(type === "productdivision" || type === "businessunits") ? `${styles.cardMargin}` : ""
        } ${type === "product" ? `${styles.cardAfter}` : ""}`}
    >
      <div className={styles.cardHeader}>
      {/*EMEA Release- - Tile Changes Group and Product Division Dashboard
        <div className={styles.cardTitle}>{` ${type === "customer" ? name : code} `}</div>*/}
      {/* <div className={styles.cardTitle}>{name}</div>
        {name.length > 28 ? <span className={styles.tooltipForCX}>{name}</span> : ""} */}
      <div className={styles.cardTitle}>{` ${type === "customer" ? name : code} (${company_region})`}</div>
      {
          name.length > 28 ? <span className={styles.tooltipForCX}>{name}</span> : "" 
          || (type === "subregion") ? <span className={styles.tooltipForCX}>{companycodes}</span> : "" 
          || (type === "customer" || (type === "product" && isDashboardPage())) ? "" : <span className={styles.tooltipForCX}>{name}</span>
      }
      </div>
      <div className={styles.cardBody}>
        <div className={styles.innerLayout}>
          <div className={styles.bodyTitle}>CX Score</div>
          {
            !cxScore ? <div className={styles.dash}>-</div> :
              <span
                className={`${styles.bodyValue} ${cxScore >= cxBenchmark ? "text-color-green" : "text-color-red"
                  }`}
              >
                {Number(cxScore).toFixed(0)}
                <span
                  className={
                    (cxScore == lastQuarterCxScore) ? `${styles.arrowNoChange}` : (cxScore > lastQuarterCxScore) ? `${styles.arrowUp}` : `${styles.arrowDown}`
                  }
                ></span>
              </span>
          }
        </div>
        <div className={styles.innerLayout}>
          <div className={styles.bodyTitle}>CAS</div>
          {
            !responseCount ? <div className={styles.dash}>-</div> :
              <span
                className={`${styles.bodyValue} ${cas >= casBenchmark ? "text-color-green" : "text-color-red"
                  }`}
              >
                {Number(cas).toFixed(0)}
                <span
                  className={
                    (cas == lastQuarterCas) ? `${styles.arrowNoChange}` : (cas > lastQuarterCas) ? `${styles.arrowUp}` : `${styles.arrowDown}`
                  }
                ></span>
              </span>
          }
        </div>
        {/*<div className={styles.innerLayout}>
          <div className={styles.bodyTitle}>Volatility</div>
          {
            !cxScore ? <div className={styles.dash}>-</div> :
              <span className={styles.bodyValue}>{volatility}</span>
          }
        </div>*/}
      </div>
      <div
        className={`${styles.cardFooter} ${getRecommendationColorCode(primaryRecommendation)}`}
      >
        <b>{getRrecommendation(primaryRecommendation)}</b>
      </div>
    </div>
  );
}

export default Product;
